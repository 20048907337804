<template>
    <v-dialog :value="dialogStatus" fullscreen persistent>
        
        <v-card>
            <v-toolbar
            dark
            color="primary"
            >
            <v-btn
                icon
                dark
                @click="closeDialog(1)"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <AskToLeft :values="statusToClose" :CloseAllDialogs="closeDialog"/>
            <v-toolbar-title>Agregar Dispositivos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                dark
                text
                @click="sendUsers()"
                >
                Finalizar
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-row dense>
                        <v-col cols="12">
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-subheader>
                                            Informacion del dispositivo
                                        </v-subheader>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <Autocomplete
                                            :Value='dialogValuesDevices.device'
                                            Label='Dispositivo'
                                            :Fn='updateDevice'
                                            :Items="typeOfDevices"
                                            :Rules=[]
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <TextField 
                                            :Value="dialogValuesDevices.serialNumber"
                                            Type="text"
                                            Label="Numero de Serie"
                                            :Fn="updateSN"
                                            :Dark="false"
                                        />
                                    </v-col>
                                    
                                    <v-col cols="12" sm="6" md="4">
                                        <TextField 
                                            :Value="dialogValuesDevices.IMEI"
                                            Type="text"
                                            Label="IMEI"
                                            :Fn="updateIMEI"
                                            :Dark="false"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="2" md="5">
                                        <Autocomplete
                                            :Value='dropdownMark'
                                            Label='Marca'
                                            :Fn='updateDropdownMark'
                                            :GetValues="getDropdownMarks"
                                            :Items="dropdownMarks"
                                            :Rules=[]
                                        />
                                    </v-col>
                                    <v-col cols="1" sm="1" md="1">
                                        <ButtonToAdd :Fn='showAddMarkDialog'/>
                                        <AddMark/>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="5" v-if="dropdownMark!=undefined&&dropdownMark.id!=undefined&&dropdownMark.id!=''">
                                        <Autocomplete
                                            :Value='dropdownModel'
                                            Label='Modelo'
                                            :Fn='updateDropdownModel'
                                            :GetValues="getDropdownModels"
                                            :Items="dropdownModels"
                                            :Rules=[]
                                        />
                                    </v-col>
                                    <v-col cols="1" sm="1" md="1" v-if="dropdownMark!=undefined&&dropdownMark.id!=undefined&&dropdownMark.id!=''">
                                        <ButtonToAdd :Fn="showAddModelDialog"/>
                                        <AddModel/>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <TextField 
                                            :Value="dialogValuesDevices.username"
                                            Type="text"
                                            Label="Usuario"
                                            :Fn="updateUsername"
                                            :Dark="false"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <TextField 
                                            :Value="dialogValuesDevices.account"
                                            Type="text"
                                            Label="Cuenta"
                                            :Fn="updateAccount"
                                            :Dark="false"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <DatePicker :DateValue="dialogValuesDevices.dateInitAccount" Label="Fecha inicio cuenta" :Dialog="dialogValuesDevices.accountDateDialog" :Fn="updateDateInitAccount"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-subheader>
                                            Informacion del SIM
                                        </v-subheader>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <Autocomplete
                                            :Value="dialogValuesSim.carrier"
                                            Label='Carrier'
                                            :Fn='updateCarrier'
                                            :Items="carriers"
                                            :Rules=[]
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <TextField 
                                            :Value="dialogValuesSim.phoneNumber"
                                            Type="number"
                                            Label="Numero de telefono"
                                            :Fn="updatePhoneNumber"
                                            :Dark="false"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <TextField 
                                            :Value="dialogValuesSim.simNumber"
                                            Type="number"
                                            Label="Numero de SIM"
                                            :Fn="updateSimNumber"
                                            :Dark="false"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <DatePicker :DateValue="dialogValuesSim.dateInitSim" Label="Fecha inicio SIM" :Dialog="dialogValuesSim.simDateInitDialog" :Fn="updateDateInitSim"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <DatePicker :DateValue="dialogValuesSim.dateEndSim" Label="Fecha fin SIM" :Dialog="dialogValuesSim.simDateEndDialog" :Fn="updateDateEndSim"/>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <TextField 
                                            :Value="dialogValuesSim.parentAccount"
                                            Type="text"
                                            Label="Cuenta padre"
                                            :Fn="updateParentAccount"
                                            :Dark="false"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <TextField 
                                            :Value="dialogValuesSim.childAccount"
                                            Type="text"
                                            Label="Cuenta hija"
                                            :Fn="updateChildAccount"
                                            :Dark="false"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <TextField 
                                            :Value="dialogValuesSim.contractNumber"
                                            Type="text"
                                            Label="N° Contrato"
                                            :Fn="updateContractNumber"
                                            :Dark="false"
                                        />
                                    </v-col>
                                    
                                </v-row>
                            </v-container> 
                            <v-container>
                                <v-row dense>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-subheader>
                                            Informacion del Cliente
                                        </v-subheader>
                                    </v-col>
                                    <v-col cols="10" sm="4" md="5">
                                        <Autocomplete
                                            :Value="dropdownClient"
                                            Label='Cliente'
                                            :Fn='updateDropdownClient'
                                            :GetValues='getDropdownClients'
                                            :Items="dropdownClients"
                                            :Rules=[]
                                        />
                                    </v-col>
                                    <v-col cols="2" sm="2" md="1">
                                        <ButtonToAdd :Fn="showAddClientDialog"/>
                                    </v-col>
                                    <v-col cols="10" sm="4" md="5">
                                        <AutocompleteMultiselect
                                            :Value="dropdownExternalUser"
                                            Label='Usuario Externo'
                                            :Fn='updateDropdownExtUsr'
                                            :GetValues='getDropdownExternalUsers'
                                            :Items="dropdownExternalUsers"
                                            :Rules=[]
                                        />
                                    </v-col>
                                    <v-col cols="2" sm="2" md="1">
                                        <ButtonToAdd :Fn="showExternalUserAddDialog"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12">
                            <v-simple-table
                            dense
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th v-for="header in addDeviceHeaders" :key="header">
                                            {{header}}
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item,index) in listOfDevicesToAdd" :key="index"
                                        >
                                            <td><v-btn x-small rounded color="red" dark @click="deleteToList(index)"><v-icon small>mdi-close</v-icon></v-btn></td>
                                            <td><v-btn x-small rounded color="blue" dark @click="editToList(index)"><v-icon small>mdi-pencil</v-icon></v-btn></td>
                                            <td>{{ index+1 }}</td>
                                            <td>{{ item.device }}</td>
                                            <td>{{ item.SN }}</td>
                                            <td>{{ item.IMEI }}</td>
                                            <td>{{ item.dropdownMark.name}}</td>
                                            <td>{{ item.dropdownModel.name}}</td>
                                            <td>{{ item.username }}</td>
                                            <td>{{ item.account }}</td>
                                            <td>{{ item.dateInitAccount }}</td>
                                            <td>{{ item.carrier }}</td>
                                            <td>{{ item.phoneNumber }}</td>
                                            <td>{{ item.simNumber }}</td>
                                            <td>{{ item.parentAccount }}</td>
                                            <td>{{ item.childAccount }}</td>
                                            <td>{{ item.contractNumber  }}</td>
                                            <td>{{ item.dateInitSim}}</td>
                                            <td>{{ item.dateEndSim}}</td>
                                            <td>{{ item.dropdownClient.name }}</td>
                                            <td>{{ 
                                                item.dropdownExternalUser.map(
                                                    function(el){
                                                        return el.name
                                                }) 
                                            }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-container>
                <FAB :buttons="[{
                    color: 'green',
                    icon: 'mdi-plus',
                    name: 'Agregar a la lista',
                    fn: addToList
                }]"/>
            </v-card-text>
        </v-card>
        <AddClient/>
        <AddExtUser/>
    </v-dialog>
</template>
<script>
import AskToLeft from '../../Complements&Buttons/YouWantToLeft'
import FAB from '../../Complements&Buttons/fab'
import AddClient from '../ClientsDialogs/addDialog'
import AddExtUser from '../ExtUsersDialogs/extUserAdd'
import AddMark from '../MarksDialogs/addMark'
import AddModel from '../ModelsDialog/addModel'
import TextField from '../../StandardizedComponents/textFields'
import Autocomplete from '../../StandardizedComponents/autocompleteFields'
import AutocompleteMultiselect from '../../StandardizedComponents/autocompleteMultiselect.vue'
import ButtonToAdd from '../../StandardizedComponents/buttonToAdd'
import DatePicker from '../../StandardizedComponents/datePicker'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
    name: 'add-device-dialog',
    components:
    {
        AskToLeft,
        AutocompleteMultiselect,
        FAB,
        AddClient,
        AddExtUser,
        AddMark,
        AddModel,
        TextField,
        Autocomplete,
        ButtonToAdd,
        DatePicker
    },
    created(){
        this.$store.commit('ExternalUsers/updateDropdownExtUsr',[],{root:true})
    },
    computed:{
        ...mapState('Devices',['typeOfDevices']),
        ...mapState('Devices/AddDevices',[
            'dialogStatus',
            'dialogValuesDevices',
            'addDeviceTable',
            'addDeviceHeaders',
            'statusToClose',
            'listOfDevicesToAdd'
        ]),

        ...mapState('Sims',[
            'carriers',
        ]),
        ...mapState('Sims/AddSims',[
            'dialogValuesSim'
        ]),

        ...mapState('Marks',['dropdownMarks','dropdownMark']),
        ...mapState('Models',['dropdownModels','dropdownModel']),
        ...mapState('ExternalUsers',['dropdownExternalUsers','dropdownExternalUser']),
        ...mapState('Clients',['dropdownClients','dropdownClient']),

    },
    methods:{

        ...mapActions('Devices/AddDevices',[
            'closeDialog',
            'addDevices',
            'add',
            'addToList',
            'editToList',
            'sendUsers'
        ]),
        ...mapMutations('Devices/AddDevices',[
            'updateSN',
            'updateDevice',
            'updateIMEI',
            'updateUsername',
            'updateAccount',
            'updateDateInitAccount',
            'deleteToList'
        ]),

        ...mapActions('Marks',['getDropdownMarks']),
        ...mapMutations('Marks',['updateDropdownMark','showAddMarkDialog']),

        ...mapActions('Models',['getDropdownModels']),
        ...mapMutations('Models',['updateDropdownModel','showAddModelDialog']),

        ...mapMutations('Sims/AddSims',[
            'updateCarrier',
            'updatePhoneNumber',
            'updateSimNumber',
            'updateParentAccount',
            'updateChildAccount',
            'updateContractNumber',
            'updateDateInitSim',
            'updateDateEndSim'
        ]),

        ...mapActions('ExternalUsers',['getDropdownExternalUsers']),
        ...mapMutations('ExternalUsers',['updateDropdownExtUsr','showExternalUserAddDialog']),

        ...mapActions('Clients',['getDropdownClients']),
        ...mapMutations('Clients',['updateDropdownClient','showAddClientDialog']),
        openAddClient(){
            this.$store.commit('moduloMark/showAddMark')
        },
        openAddModel(){
            this.$store.commit('moduloModel/showAddModel')
        },
    },
}
</script>
<template>
    <div id="app">
        <Navbar/>
        <Table class="mt-3"/>
    </div>
</template>

<script>
import Navbar from '../components/Navbar'
import Table from '../components/Tables/devicesTable'

export default {
    name: 'principal-devices',
    components: {
        Navbar,
        Table,
    }
}
</script>
<template>
  <v-row justify="center">
    <v-dialog
      :value="values"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          ¿Quieres salir de esta vista?
        </v-card-title>
        <v-card-text>Al salir de este formulario todos los datos guardados se perderan</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="CloseAllDialogs(1)"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green"
            text
            @click="CloseAllDialogs(2)"
          >
            Si, cerrar y continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
    props:{
        values: Boolean,
        CloseAllDialogs: Function
    }
}
</script>
<template>
    <div id="app">
        <v-app id="inspire">
            <v-data-table
                v-model="Selected"
                :headers="headers"
                :items="devices"
                item-key="device_id"
                class="elevation-1"
                show-select
                :loading="loading"
                loading-text="Cargando..."
                no-data-text="No data found"
                dense
                :page="page"
                :footer-props="{
                    itemsPerPageOptions:[5,10,15,50,100,200]
                }"
                :server-items-length="dataLength"
                header.account
                @pagination="getAllDevices($event)"
            >
                
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-row>
                            <v-spacer></v-spacer>
                            <SearchBar  :setSearchTerm="getSearchedTermData"/> 
                            
                        </v-row>
                    </v-toolbar>
                </template>
                <template  v-slot:item.device_date_init="{ item }">
                    {{item.device_date_init.substr(0,10)}}
                </template>
                <template  v-slot:item.device_date_end="{ item }">
                    {{item.device_date_end.substr(0,10)}}
                </template>
                <template  v-slot:item.sim_date_init="{ item }">
                    {{item.sim_date_init.substr(0,10)}}
                </template>
                <template  v-slot:item.sim_date_end="{ item }">
                    {{item.sim_date_end.substr(0,10)}}
                </template>
                <template  v-slot:item.externalUsers="{ item }" >
                    <v-list  dense class="mx-n4" width="150px">
                        <v-list-group
                            :value="false"
                        >
                            <template v-slot:activator>
                            <v-list-item-title >Usuarios</v-list-item-title>
                            </template>
                            <v-list-item
                                inactive
                                v-for="(user, i) in item.externalUsers"
                                :key="i"
                                link
                            >
                                <v-list-item-title>{{user.name}}</v-list-item-title>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </template>
                <template  v-slot:item.actions="{ item }">
                    <TableButtons
                        :Buttons="[
                            {
                                icon:'mdi-pencil',
                                Fn:showUpdateDeviceDialog,
                                fnParams:item
                            },
                            {
                                icon:'mdi-delete',
                                Fn:showDeleteDeviceDialog,
                                fnParams:item
                            } 
                        ]"
                    />
                </template>
                
            </v-data-table>

            <!-- DIALOG ADD RADIO -->
            <FAB v-if="selectedItems.length===0"
            :buttons="[{
                color: 'primary',
                icon: 'mdi-plus',
                name: 'Agregar un dispositivo',
                fn: showAddDeviceDialog
            }]"/>
            <FAB v-else
            :buttons="[
                {
                    color: 'error',
                    icon: 'mdi-delete',
                    name: 'Eliminar dispositivos',
                    fn: showDeleteMultipleDevicesDialog
                },
                {
                    color: 'primary',
                    icon: 'mdi-pencil',
                    name: 'Editar dispositivos',
                    fn: showUpdateMultipleDevicesDialog
                },

            ]"/>
            <AddDevice/>
            <EditDevice v-if="dialogStatus"/>
            <MultipleEditDevice/>
            <DeleteDevice/>
            <MultipleDeleteDevice/>
        </v-app>
    </div>
</template>

<script>
import { mapMutations, mapState,mapActions } from 'vuex'
import TableButtons from '../Complements&Buttons/tableButtons'
import EditDevice from  '../Dialogs/DevicesDialogs/editDevice'
import MultipleEditDevice from '../Dialogs/DevicesDialogs/editMultipleDevices'
import DeleteDevice from  '../Dialogs/DevicesDialogs/deleteDevice'
import MultipleDeleteDevice from '../Dialogs/DevicesDialogs/deleteMultipleDevices'
import AddDevice from  '../Dialogs/DevicesDialogs/addDevice'
import FAB from '../Complements&Buttons/fab'
import SearchBar from '../Complements&Buttons/searchBar'

export default {
    components: {
        TableButtons,
        FAB,
        SearchBar,
        EditDevice,
        DeleteDevice,
        AddDevice,
        MultipleEditDevice,
        MultipleDeleteDevice
    },
  computed: {
        ...mapState('Devices/UpdateDevices',['dialogStatus']),
        ...mapState('Devices/FetchDevices',[
            'devices',
            'headers',
            'loading',
            'search',
            'dataLength',
            'page'
            ]),
        ...mapState('Devices',[
            'selectedItems'
            ]),
        Selected:{
            get(){
                return this.selectedItems
            },
            set(value){
                this.$store.dispatch('Devices/updateSelectedItems',value)
            }
        }
  },

  methods: {
      clg(value){
          console.log(value)
      },
    ...mapMutations('Devices/AddDevices',['showAddDeviceDialog']),
    ...mapActions('Devices/FetchDevices',['getSearchedTermData']),
    ...mapMutations('Devices',['updateSelectedItems']),
    ...mapActions('Devices/FetchDevices',['getAllDevices']),
    ...mapActions('Devices/UpdateDevices',['showUpdateDeviceDialog','showUpdateMultipleDevicesDialog']),
    ...mapActions('Devices/DeleteDevices',['showDeleteDeviceDialog','showDeleteMultipleDevicesDialog']),
   
  }
}
</script>
<style scoped>
    .style{
        
    };
    .style2{
        background-color:rgb(0,255,255)
    }
</style>
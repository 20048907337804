<template>
  <v-dialog
    :value="dialogEditMultipleStatus"
    :persistent="true"
    max-width="800px"
    :retain-focus="false"
  >
    <v-card color="basil" class="px-10">
      <v-card-title class="text-center justify-center">
        <p >Editar muchos dispositivos</p>
      </v-card-title>

          <Autocomplete
            :Value="dropdownClient"
            Label='Cliente'
            :Fn='updateDropdownClient'
            :GetValues='getDropdownClients'
            :Items="dropdownClients"
            :Rules=[]
          />
          <AutocompleteMultiselect
            :Value="dropdownExternalUser"
            Label='Usuario Externo'
            :Fn='updateDropdownExtUsr'
            :GetValues='getDropdownExternalUsers'
            :Items="dropdownExternalUsers"
            :Rules=[]
          />
          <DatePicker :DateValue="dialogValuesDevices.dateInitAccount" Label="Fecha inicio cuenta" :Dialog="dialogValuesDevices.accountDateDialog" :Fn="updateDateInitAccount" class="mx-6 mt-2" />
          <DatePicker :DateValue="dialogValuesSim.dateInitSim" Label="Fecha inicio SIM" :Dialog="dialogValuesSim.simDateInitDialog" :Fn="updateDateInitSim"/>
          <DatePicker :DateValue="dialogValuesSim.dateEndSim" Label="Fecha fin SIM" :Dialog="dialogValuesSim.simDateEndDialog" :Fn="updateDateEndSim"/>

      <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    text
                    @click="closeUpdateMultipleDevicesDialog()"
                >
                Close
                </v-btn>
                <v-btn
                    color="green"
                    text
                    @click="updateMultipleDevices()"
                >
                    Save
                </v-btn>
            </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState,mapMutations,mapActions } from "vuex";
import Autocomplete from '../../StandardizedComponents/autocompleteFields'
import DatePicker from '../../StandardizedComponents/datePicker'
import AutocompleteMultiselect from '../../StandardizedComponents/autocompleteMultiselect.vue'

export default {
  name: 'edit-multiple-devices-dialog',
    data(){
        return{
            tab:true
        }
    },
    components:{
        Autocomplete,
        DatePicker,
        AutocompleteMultiselect
    },
  computed: {
    ...mapState('Sims/UpdateSims',['dialogValuesSim']),
    ...mapState("Devices/UpdateDevices", ["dialogEditMultipleStatus","dialogValuesDevices"]),
    ...mapState('Clients',['dropdownClient','dropdownClients']),
    ...mapState('ExternalUsers',['dropdownExternalUsers','dropdownExternalUser'])
  },
  methods:{
    ...mapActions('Devices/UpdateDevices',['updateMultipleDevices']),
    ...mapMutations('Sims/UpdateSims',['updateDateInitSim','updateDateEndSim']),
    ...mapMutations('Devices/UpdateDevices',['updateDateInitAccount','closeUpdateMultipleDevicesDialog']),
    ...mapActions('Clients',['getDropdownClients']),
    ...mapMutations('Clients',['updateDropdownClient','showAddClientDialog']),
    ...mapActions('ExternalUsers',['getDropdownExternalUsers']),
    ...mapMutations('ExternalUsers',['updateDropdownExtUsr','updateDropdownExtUsrs']),
  }
};
</script>

<style>
</style>
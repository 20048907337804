<template>
<v-dialog :value="dialogAddClient" max-width="610px">
<v-card>
        <v-card-title>
          <span class="headline">Agregar Cliente</span>
        </v-card-title>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <TextField
            :Value="dialogValuesClient.clientNumber"
            Label="Numero de cliente"
            Type="number"
            :Fn="updateClientNumber"
          />

        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <TextField
            :Value="dialogValuesClient.company"
            Label="Compañia"
            Type="text"
            :Fn="updateCompany"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <TextField
            :Value="dialogValuesClient.rfc"
            Label="RFC"
            Type="text"
            :Fn="updateRFC"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <TextField
            :Value="dialogValuesClient.phoneNumber"
            Label="Telefono"
            Type="number"
            :Fn="updatePhoneNumber"
          />
        </v-col>
        <v-col cols="12" >
            <TextField
            :Value="dialogValuesClient.email"
            Label="Correo"
            Type="text"
            :Fn="updateEmail"
          />
        </v-col>
        <v-col cols="12">
            <TextField
            :Value="dialogValuesClient.address"
            Label="Direccion"
            Type="text"
            :Fn="updateAddress"
          />
        </v-col>
        
      </v-row>
    </v-container>
    <small>*Campos obligatorios</small>
  </v-card-text>

  <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeAddClientDialog()"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="addClient()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
</template>
<script>
import { mapActions, mapMutations,mapState} from 'vuex'
import TextField from '../../StandardizedComponents/textFields'
export default {
    components:{
        TextField
    },
    computed:{
      ...mapState('Clients',['showAddClientDialog','dialogAddClient','dialogValuesClient'])
    },
    methods:{
      ...mapMutations('Clients',[
        'updateClientNumber',
        'updateCompany',
        'updateRFC',
        'updatePhoneNumber',
        'updateEmail',
        'updateAddress'
        ]),
      ...mapActions('Clients',['closeAddClientDialog','addClient']),
    }
}

</script>
<template>
  <v-dialog :value="dialogDeleteMultipleStatus" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Eliminar multiples dispositivos
      </v-card-title>

      <v-card-text class="pt-2">
        Usted esta a punto de eliminar {{selectedItems.length}} dispositivos
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeDeleteMultipleDevicesDialog">Cerrar</v-btn>
        <DeleteProgressButton :Fn="deleteMultipleDevices"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeleteProgressButton from '../../StandardizedComponents/deleteProgressButton'
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    
    computed: {
        ...mapState("Devices/DeleteDevices", ["dialogDeleteMultipleStatus"]),
        ...mapState("Devices", ["selectedItems"]),
    },
    components:{
        DeleteProgressButton
    },
    methods:{
      ...mapActions("Devices/DeleteDevices",['deleteMultipleDevices']),
      ...mapMutations("Devices/DeleteDevices",['closeDeleteMultipleDevicesDialog'])
    }
    
};
</script>

<style>
</style>
<template>
    <v-dialog
        :value="dialogStatus"
        max-width="610px"
        persistent
    >
        <v-card max-width="610px">
            <v-card-title class="headline">
                ¿Estás seguro que quieres eliminar este dispositivo?
            </v-card-title>
            <v-card-text>
                <v-card width="550px"  >
                    <v-card-title class="subheading font-weight-bold">
                    ID de dispositivo: {{ dialogValuesDevices.deviceID }}
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-list dense>
                    <v-list-item>
                        <v-list-item-content>Numero de Serie:</v-list-item-content>
                        <v-list-item-content class="align-center">
                        {{ dialogValuesDevices.serialNumber }}
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>IMEI:</v-list-item-content>
                        <v-list-item-content class="align-center">
                        {{ dialogValuesDevices.IMEI }}
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-card>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDeleteDeviceDialog"
                    >Cancel</v-btn
                >
                <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteDevice"
                    >OK</v-btn
                >
            <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState,mapMutations,mapActions } from 'vuex'
export default {
    computed:{
        ...mapState('Devices/DeleteDevices',['dialogStatus','dialogValuesDevices'])
    },
    methods:{
        ...mapMutations('Devices/DeleteDevices',['closeDeleteDeviceDialog']),
        ...mapActions('Devices/DeleteDevices',['deleteDevice'])
    }
}
</script>
<template>
<v-dialog :value="addModelDialog" max-width="610px">
<v-card>
        <v-card-title>
          <span class="headline">Agregar Modelo</span>
        </v-card-title>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col cols='12'>
            <TextField
              :Value="dialogValuesModel.name"
              Type="text"
              Label="Nombre"
              :Fn="updateName"
              :Dark="false"
          />
        </v-col>
      </v-row>
    </v-container>
    <small>*Campos obligatorios</small>
  </v-card-text>

  <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="closeAddModelDialog()"
          >
            Close
          </v-btn>
          <v-btn
            color="green"
            text
            @click="addModel()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
</template>
<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import TextField from '../../StandardizedComponents/textFields'
export default {
    name: "addModel",
    components:{
      TextField
    },
    computed:{
      ...mapState('Models',['addModelDialog','dialogValuesModel'])
    },
    methods:{
        ...mapMutations('Models',['updateName','closeAddModelDialog','resetValues']),
        ...mapActions('Models',['addModel']),
    },
    created(){
        this.getMarks
    }
}

</script>
<template>
<div>
  <v-btn
          color="red"
          text
          @mousedown="start"
          @mouseleave="stop"
          @mouseup="stop"
          @touchstart="start"
          @touchend="stop"
          @touchcancel="stop"
        >
          Eliminar
        </v-btn>
        <v-progress-circular
          :rotate="-90"

          :value="data"
          color="red"

        >
        </v-progress-circular>
</div>
</template>

<script>
export default {
    data(){
        return{
            data:0,
            interval:false,
            interval2:false
        }
    },
    props:{
        Fn: Function
    },
    methods: {
        start(){
            clearInterval(this.interval2)
            if(!this.interval){
                this.interval=setInterval(()=>{
                if(this.data<100){
                    this.data=this.data+20
                }else if(this.data===100){
                    this.$props.Fn()
                }
                }, 500)	
            }
            this.interval2 = false
            
        },
        stop(){
            
            clearInterval(this.interval)
            if(!this.interval2)
            {
                this.interval2=setInterval(()=>{
                    if(this.data>0&&this.data<101)
                    {
                        this.data=this.data-20
                    }
                },500)
            }
            
            this.interval = false
        }
    }
}
</script>

<style>

</style>
<template>
    <v-dialog :value="dialogStatus" :persistent="true" max-width="1200px" :scrollable='true' :retain-focus="false">
        <v-card outlined>
            <v-card-title>
                <span class="headline">Editar dispositivo</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-subheader>
                        Informacion del dispositivo
                    </v-subheader>
                    <v-row dense>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <Autocomplete
                                :Value='dialogValuesDevices.device'
                                Label='Dispositivo'
                                :Fn='updateDevice'
                                :Items="typeOfDevices"
                                :Rules=[]
                            />
                        </v-col>
                        
                        <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        >
                            <Autocomplete
                                :Value='dropdownMark'
                                Label='Marca'
                                :Fn='updateDropdownMark'
                                :GetValues="getDropdownMarks"
                                :Items="dropdownMarks"
                                :Rules=[]
                            />
                        </v-col>
                        <v-col
                        cols="1"
                        sm="1"
                        md="1"
                        >
                            <ButtonToAdd :Fn='showAddMarkDialog'/>
                            <AddMark/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        v-if="dropdownMark!=undefined&&dropdownMark.id!=undefined&&dropdownMark.id!=''"
                        >
                            <Autocomplete
                                :Value='dropdownModel'
                                Label='Modelo'
                                :Fn='updateDropdownModel'
                                :GetValues="getDropdownModels"
                                :Items="dropdownModels"
                                :Rules=[]
                            />
                        </v-col>
                        <v-col
                        v-if="dropdownMark!=undefined&&dropdownMark.id!=undefined&&dropdownMark.id!=''"
                        cols="1"
                        sm="1"
                        md="1"
                        >
                            <ButtonToAdd :Fn="showAddModelDialog"/>
                            <AddModel/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <TextField 
                                :Value="dialogValuesDevices.IMEI"
                                Type="text"
                                Label="IMEI"
                                :Fn="updateIMEI"
                                :Dark="false"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <TextField 
                                :Value="dialogValuesDevices.serialNumber"
                                Type="text"
                                Label="Numero de Serie"
                                :Fn="updateSN"
                                :Dark="false"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextField 
                                :Value="dialogValuesDevices.username"
                                Type="text"
                                Label="Usuario"
                                :Fn="updateUsername"
                                :Dark="false"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextField 
                                :Value="dialogValuesDevices.account"
                                Type="text"
                                Label="Cuenta"
                                :Fn="updateAccount"
                                :Dark="false"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <DatePicker :DateValue="dialogValuesDevices.dateInitAccount" Label="Fecha inicio cuenta" :Dialog="dialogValuesDevices.accountDateDialog" :Fn="updateDateInitAccount"/>
                        </v-col>  
                    </v-row>
                    <v-subheader>
                        Informacion del SIM
                    </v-subheader>
                    <v-row dense>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <Autocomplete
                                :Value="dialogValuesSim.carrier"
                                Label='Carrier'
                                :Fn='updateCarrier'
                                :Items="carriers"
                                :Rules=[]
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextField 
                                :Value="dialogValuesSim.phoneNumber"
                                Type="number"
                                Label="Numero de telefono"
                                :Fn="updatePhoneNumber"
                                :Dark="false"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextField 
                                :Value="dialogValuesSim.simNumber"
                                Type="text"
                                Label="Numero de SIM"
                                :Fn="updateSimNumber"
                                :Dark="false"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextField 
                                :Value="dialogValuesSim.parentAccount"
                                Type="text"
                                Label="Cuenta padre"
                                :Fn="updateParentAccount"
                                :Dark="false"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextField 
                                :Value="dialogValuesSim.childAccount"
                                Type="text"
                                Label="Cuenta hija"
                                :Fn="updateChildAccount"
                                :Dark="false"
                            />
                        </v-col>  
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextField 
                                :Value="dialogValuesSim.contractNumber"
                                Type="text"
                                Label="N° Contrato"
                                :Fn="updateContractNumber"
                                :Dark="false"
                            />
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <DatePicker :DateValue="dialogValuesSim.dateInitSim" Label="Fecha inicio SIM" :Dialog="dialogValuesSim.simDateInitDialog" :Fn="updateDateInitSim"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <DatePicker :DateValue="dialogValuesSim.dateEndSim" Label="Fecha fin SIM" :Dialog="dialogValuesSim.simDateEndDialog" :Fn="updateDateEndSim"/>
                        </v-col>    
                    </v-row>
                    <v-row dense>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-subheader>
                                            Informacion del Cliente
                                        </v-subheader>
                                    </v-col>
                                    <v-col cols="10" sm="4" md="5">
                                        <Autocomplete
                                            :Value="dropdownClient"
                                            Label='Cliente'
                                            :Fn='updateDropdownClient'
                                            :GetValues='getDropdownClients'
                                            :Items="dropdownClients"
                                            :Rules=[]
                                        />
                                    </v-col>
                                    <v-col cols="2" sm="2" md="1">
                                        <ButtonToAdd :Fn="showAddClientDialog"/>
                                    </v-col>
                                    <v-col cols="10" sm="4" md="5">
                                        <AutocompleteMultiselect
                                            :Value="dropdownExternalUser"
                                            Label='Usuario Externo'
                                            :Fn='updateDropdownExtUsr'
                                            :GetValues='getDropdownExternalUsers'
                                            :Items="dropdownExternalUsers"
                                            :Rules=[]
                                        />
                                        <v-chip-group
                                            v-if="chipsExternalUsers"
                                            multiple
                                            active-class="primary--text"
                                        >
                                            <v-chip
                                            v-for="(ExternalUser ,index) in chipsExternalUsers"
                                            color="primary"
                                            :key="index"
                                            close
                                            @click:close="deleteExternalUserList(ExternalUser)"
                                            >
                                            {{ ExternalUser.name }}
                                            </v-chip>
                                        </v-chip-group>
                                    </v-col>
                                    <v-col cols="2" sm="2" md="1">
                                        <ButtonToAdd :Fn="showExternalUserAddDialog" v-if="dropdownExternalUser.length==0"/>
                                        <ButtonToAdd :Fn="addExternalUserList" v-else/>
                                    </v-col>
                                </v-row>
                </v-container>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    text
                    @click="closeUpdateDeviceDialog()"
                >
                Close
                </v-btn>
                <v-btn
                    color="green"
                    text
                    @click="UpdateDeviceInformation()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
        <AddClient/>
        <AddExtUser/>
    </v-dialog>
</template>
<script>
import AddMark from '../MarksDialogs/addMark'
import AddModel from '../ModelsDialog/addModel'
import TextField from '../../StandardizedComponents/textFields'
import Autocomplete from '../../StandardizedComponents/autocompleteFields'
import AutocompleteMultiselect from '../../StandardizedComponents/autocompleteMultiselect.vue'
import ButtonToAdd from '../../StandardizedComponents/buttonToAdd'
import DatePicker from '../../StandardizedComponents/datePicker'
import AddClient from '../ClientsDialogs/addDialog'
import AddExtUser from '../ExtUsersDialogs/extUserAdd'
import {mapActions, mapMutations, mapState} from 'vuex' 
export default {
    name: 'edit-device-dialog',
    components:{
        AddMark,
        AddModel,
        TextField,
        Autocomplete,
        ButtonToAdd,
        DatePicker,
        AddClient,
        AddExtUser,
        AutocompleteMultiselect
    },
    computed:{
        ...mapState('Devices/UpdateDevices',['dialogStatus','dialogValuesDevices']),
        ...mapState('Devices',['typeOfDevices']),
        ...mapState('Sims/UpdateSims',['dialogValuesSim']),
        ...mapState('Sims',['carriers']),
        ...mapState('Clients',['dropdownClient','dropdownClients']),
        ...mapState('ExternalUsers',['dropdownExternalUsers','dropdownExternalUser','chipsExternalUsers']),
        ...mapState('Marks',['dropdownMarks','dropdownMark']),
        ...mapState('Models',['dropdownModels','dropdownModel'])
    },
    methods:{
        ...mapActions('Devices/UpdateDevices',['UpdateDeviceInformation']),
        ...mapMutations('Devices/UpdateDevices',['updateDevice','updateIMEI','updateSN','updateUsername','updateAccount','updateDateInitAccount','closeUpdateDeviceDialog']),
        ...mapMutations('Sims/UpdateSims',['updateCarrier','updatePhoneNumber','updateSimNumber','updateParentAccount','updateChildAccount','updateContractNumber','updateDateInitSim','updateDateEndSim']),
        ...mapActions('Marks',['getDropdownMarks']),
        ...mapMutations('Marks',['updateDropdownMark','showAddMarkDialog']),
        ...mapActions('Models',['getDropdownModels']),
        ...mapMutations('Models',['updateDropdownModel','showAddModelDialog']),
        ...mapActions('Clients',['getDropdownClients']),
        ...mapMutations('Clients',['updateDropdownClient','showAddClientDialog']),
        ...mapActions('ExternalUsers',['getDropdownExternalUsers','deleteExternalUserList','addExternalUserList']),
        ...mapMutations('ExternalUsers',['updateDropdownExtUsr','updateDropdownExtUsrs','showExternalUserAddDialog']),
    }
}
</script>
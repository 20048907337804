<template>
<v-dialog :value="addMarkDialog" max-width="610px">
<v-card>
        <v-card-title>
          <span class="headline">Agregar Marca</span>
        </v-card-title>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <TextField
            :Value="dialogValuesMark.name"
            Type="text"
            Label="Nombre"
            :Fn="updateName"
            :Dark="false"
          />
        </v-col>
  
      </v-row>
    </v-container>
    <small>*Campos obligatorios</small>
  </v-card-text>

  <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="closeAddMarkDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="green"
            text
            @click="addMark"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
</template>
<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import TextField from '../../StandardizedComponents/textFields'
export default {
    name: "addMark",
    components:{
      TextField
    },
    computed:{
      ...mapState('Marks',['dialogValuesMark','addMarkDialog'])
    },
    methods:{
        ...mapMutations('Marks',['updateName','closeAddMarkDialog']),
        ...mapActions('Marks',['addMark','getMarks']),
        getMarks(){
            this.$store.dispatch('TableDevices/getMarksOnEdit')
        }
    }
}

</script>
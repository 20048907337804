var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.devices,"item-key":"device_id","show-select":"","loading":_vm.loading,"loading-text":"Cargando...","no-data-text":"No data found","dense":"","page":_vm.page,"footer-props":{
                itemsPerPageOptions:[5,10,15,50,100,200]
            },"server-items-length":_vm.dataLength,"header.account":""},on:{"pagination":function($event){return _vm.getAllDevices($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-spacer'),_c('SearchBar',{attrs:{"setSearchTerm":_vm.getSearchedTermData}})],1)],1)]},proxy:true},{key:"item.device_date_init",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.device_date_init.substr(0,10))+" ")]}},{key:"item.device_date_end",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.device_date_end.substr(0,10))+" ")]}},{key:"item.sim_date_init",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.sim_date_init.substr(0,10))+" ")]}},{key:"item.sim_date_end",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.sim_date_end.substr(0,10))+" ")]}},{key:"item.externalUsers",fn:function(ref){
            var item = ref.item;
return [_c('v-list',{staticClass:"mx-n4",attrs:{"dense":"","width":"150px"}},[_c('v-list-group',{attrs:{"value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Usuarios")])]},proxy:true}],null,true)},_vm._l((item.externalUsers),function(user,i){return _c('v-list-item',{key:i,attrs:{"inactive":"","link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(user.name))])],1)}),1)],1)]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('TableButtons',{attrs:{"Buttons":[
                        {
                            icon:'mdi-pencil',
                            Fn:_vm.showUpdateDeviceDialog,
                            fnParams:item
                        },
                        {
                            icon:'mdi-delete',
                            Fn:_vm.showDeleteDeviceDialog,
                            fnParams:item
                        } 
                    ]}})]}}]),model:{value:(_vm.Selected),callback:function ($$v) {_vm.Selected=$$v},expression:"Selected"}}),(_vm.selectedItems.length===0)?_c('FAB',{attrs:{"buttons":[{
            color: 'primary',
            icon: 'mdi-plus',
            name: 'Agregar un dispositivo',
            fn: _vm.showAddDeviceDialog
        }]}}):_c('FAB',{attrs:{"buttons":[
            {
                color: 'error',
                icon: 'mdi-delete',
                name: 'Eliminar dispositivos',
                fn: _vm.showDeleteMultipleDevicesDialog
            },
            {
                color: 'primary',
                icon: 'mdi-pencil',
                name: 'Editar dispositivos',
                fn: _vm.showUpdateMultipleDevicesDialog
            } ]}}),_c('AddDevice'),(_vm.dialogStatus)?_c('EditDevice'):_vm._e(),_c('MultipleEditDevice'),_c('DeleteDevice'),_c('MultipleDeleteDevice')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }